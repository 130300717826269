import React, { useContext } from "react";
import { Link } from "gatsby";
import Unsubscribed from "../components/unsubscribed/Unsubscribed";
import { decodeQueryParameter, parseUrlSearch } from "utils/queryParameters";
import { Query, Mutation } from "react-apollo";
import { gql } from "apollo-boost";
import { GetFoundByBuyers } from "components/footer-cta/getFoundByBuyers";
import { AuthContext } from "auth/AuthContext";
import { SAVE_SUBSCRIPTIONS_MUTATION } from "components/emailPreferences/EmailPreferencesContainer";
import { Spinner } from "components/spinner/spinner";

const QUERY_GET_NEWSLETTER = gql`
  query getNewsletter($id: ID) {
    newsletter(id: $id) {
      title
    }
  }
`;

function afterMutation(reason) {
  // if they give a reason, route them to the news pages per req't
  if (reason !== "") {
    setTimeout(function() {
      window.location = "https://news.thomasnet.com/";
    }, 1000);
  }
}

const Unsubscribe = ({ location }: { location: any }) => {
  const { user } = useContext(AuthContext);
  const params = parseUrlSearch(location.search);
  let email = decodeQueryParameter(params["email"]);
  const message = decodeQueryParameter(params["message"]);

  if (!email) {
    email = user && user.email ? user.email : "";
  }

  return (
    <>
      <section className="page-utility">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="https://www.thomasnet.com">Home</a>
              </li>
              <li className="breadcrumb-item">
                <Link to="/email-preferences/">Email Preferences</Link>
              </li>
              <li className="breadcrumb-item">
                <span xmlns="http://rdf.data-vocabulary.org/#" />
                <span className="breadcrumb_last">Unsubscribed</span>
              </li>
            </ol>
          </nav>
        </div>
      </section>
      <section className="page-content page-content--single-column">
        <div className="container container--sm">
          <header className="page-header">
            <h1 className="page-header__title">Unsubscribed</h1>
          </header>
          <div className="content-card content-card--pattern mb-4">
            <Query query={QUERY_GET_NEWSLETTER} variables={{ id: message }}>
              {({ loading, data: { newsletter } }) => {
                if (loading) {
                  return <Spinner />;
                }

                if (!message || !email) {
                  return <div>Missing Message Id or Email</div>;
                }

                return (
                  <Mutation mutation={SAVE_SUBSCRIPTIONS_MUTATION}>
                    {(mutate: any, { loading: mutationLoading }: any) => (
                      <Unsubscribed
                        newsletter={newsletter}
                        unsubFunction={(reason: string) => {
                          let subscriptions = [
                            {
                              lastModified: new Date().toISOString(),
                              newsletterId: message,
                              status: "Unsubscribed"
                            }
                          ];

                          // if reason, add to sub object
                          if (reason !== "") {
                            subscriptions[0].reason = reason;
                          }

                          mutate(
                            {
                              variables: {
                                email,
                                subscriptions: subscriptions
                              }
                            },
                            afterMutation(reason)
                          );
                        }}
                        resubFunction={() => {
                          let subscriptions = [
                            {
                              lastModified: new Date().toISOString(),
                              newsletterId: message,
                              status: "Subscribed"
                            }
                          ];
                          mutate({
                            variables: {
                              email,
                              subscriptions: subscriptions
                            }
                          });
                        }}
                        loading={mutationLoading}
                      />
                    )}
                  </Mutation>
                );
              }}
            </Query>
          </div>
        </div>
      </section>
      <GetFoundByBuyers />
    </>
  );
};

export default Unsubscribe;
