import React from "react";
import { Spinner } from "../spinner/spinner";
import UnsubscribeOption from "./UnsubscribeOption";
import SavedNotification from "components/savedNotification/savedNotification";
import "./unsubscribed.scss";

const unsubscribeReasons = [
  "I am not interested in this content",
  "I don't remember subscribing",
  "I am receiving too many emails",
  "Other"
];

class Unsubscribed extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      reason: "",
      otherReason: "",
      saved: false
    };

    props.unsubFunction("");
  }

  handleChange = (option: string) => {
    console.log("handling a change", option);
    this.setState({
      reason: option,
      otherReason: ""
    });
  };

  handleResubscribe = () => {
    this.props.resubFunction();
    this.setState({
      ...this.state,
      saved: true
    });
  };

  onSave = () => {
    console.log("testing", this.state.saved);
    return this.state.saved ? (
      <span style={{ marginLeft: "1rem" }}>
        {" "}
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          className="icon"
        >
          <title>ico-check-mark</title>
          <path
            d="M27.604 3.372a1.155 1.155 0 0 0-1.628.112L11.614 19.958 6.477 17.15a1.19 1.19 0 0 0-1.503 1.784l5.457 6.865a2.473 2.473 0 0 0 4.014-.198l.179-.278L27.816 4.867a1.153 1.153 0 0 0-.212-1.495z"
            fill="green"
            fillRule="nonzero"
          />
        </svg>
        {"  "}Your preferences have been saved
      </span>
    ) : (
      ""
    );
  };

  render() {
    const { unsubFunction, loading, newsletter }: any = this.props;
    const { reason, otherReason }: any = this.state;

    if (loading) {
      return <Spinner />;
    }

    if (!newsletter) {
      return <div>Message ID is invalid.</div>;
    }

    return (
      <>
        <p>
          You have unsubscribed from <b>{newsletter && newsletter.title}</b>
          .<br />
          Didn't mean to?{" "}
          <span className="link" onClick={() => this.handleResubscribe()}>
            Resubscribe
          </span>
          {this.onSave()}
        </p>
        <hr className="thick-line-break" />

        <p>If you have a moment, please let us know why you unsubscribed.</p>

        <form>
          <div className="unsubscribed">
            {unsubscribeReasons.map((option, index) => (
              <UnsubscribeOption
                key={index}
                isChecked={option === reason}
                onChange={this.handleChange}
                name={index.toString()}
                label={option}
                textboxValue={otherReason}
                updateOtherReason={(v: string) => {
                  console.log("Setting:", v);
                  this.setState({
                    otherReason: v
                  });
                }}
              />
            ))}
          </div>

          <button
            onClick={() => {
              unsubFunction(otherReason !== "" ? otherReason : reason);
            }}
            className="btn btn-primary"
            type="button"
          >
            Submit Feedback
          </button>
        </form>
      </>
    );
  }
}

export default Unsubscribed;
