import React from "react";
interface UnsubscribeOptionProps {
  isChecked: boolean;
  onChange: any;
  name: string;
  label: string;
  textBoxValue: string;
  updateOtherReason: Function;
  key: number;
}

const UnsubscribeOption: React.SFC<UnsubscribeOptionProps> = ({
  isChecked,
  onChange,
  name,
  label,
  textBoxValue,
  updateOtherReason,
  key
}) => {
  const isOther = label === "Other";
  return (
    <div className="flex-row-container">
      <input
        style={{ marginTop: "5px", marginRight: "10px" }}
        type="radio"
        name={name}
        id={name}
        checked={isChecked}
        onChange={() => {
          onChange(label);
        }}
      />
      {isOther && isChecked ? (
        <input
          type="text"
          value={textBoxValue}
          onChange={e => {
            updateOtherReason(e.target.value);
          }}
        ></input>
      ) : (
        <label htmlFor={name} className="font-weight-light">
          {label}
        </label>
      )}
    </div>
  );
};

export default UnsubscribeOption;
